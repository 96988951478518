body {
  background-color: #017867;
  color: #fefdff;
  width: 100vw;
  height: 100vh;
  margin: 0;
  font-family: Geist, sans-serif;
  overscroll-behavior: none;
  overflow: hidden;
  /*font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',*/
  /*  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',*/
  /*  sans-serif;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  width: 100vw;
  height: 100vh;
}
